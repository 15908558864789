<template>
    <modal v-show="isDuplicate" @close="closeModal">
        <span slot="header">Fakturadubblett</span>
        <span slot="body" class="text-sm">Duplicerade poster hittades för denna faktura</span>
        <span slot="footer">
            <group-button>
                <span slot="button1">
                    <el-button size="mini" type="primary" @click="viewDuplicateDocument()">View Document</el-button>
                </span>
                <span slot="button2">
                    <el-button size="mini" type="primary" @click="saveDuplicateInvoice()" :loading="duplicateCheck">{{ getButtonText }}</el-button>
                </span>
            </group-button>
        </span>
    </modal>
</template>
<script>
import Enum from "../supplierInvoice.enum";

export default {
    props: ["isDuplicate", "status", "duplicateCheck"],

    components: {
        modal: () => import(/* webpackChunkName: "Modal" */ "../../../components/modal"),
        "group-button": () => import(/* webpackChunkName: "Pdf" */ "../../../components/buttonGroup"),
    },

    computed: {
        getButtonText() {
            if (this.status == Enum.CreditorInvoiceStatus.Utkast) {
                return "Spara och nästa";
            } else if (this.status == Enum.CreditorInvoiceStatus.Attesteras) {
                return "Attest";
            }
            return "Spara och nästa";
        },
    },

    methods: {
        saveDuplicateInvoice() {
            if (this.status == Enum.CreditorInvoiceStatus.Utkast) {
                this.$emit("saveDuplicateInvoice");
            } else if (this.status == Enum.CreditorInvoiceStatus.Attesteras) {
                this.$emit("bookDuplicateInvoice");
            }
        },

        viewDuplicateDocument() {
            this.$emit("viewDuplicateDocument");
        },

        closeModal() {
            this.$store.commit("supplierInvoice/setSaveBtnLoading", false);
            this.$store.commit("supplierInvoice/setSendBtnLoading", false);
            this.$emit("update:isDuplicate", false);
        },
    },
};
</script>
